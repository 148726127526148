/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AccountV2,
  AllAccountsV2,
  AllSubscriptionsResponse,
  BasicErrorResponseBusinessalreadyhasanaccountwithsamenickname,
  BasicErrorResponseInternalServerError,
  BasicErrorResponseInvalidAccount,
  BasicErrorResponseProductnotfound,
  BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid,
  BasicErrorResponseValidationerrorInvaliduuid,
  BasicErrorResponseValidationerroronbody,
  ChangeNicknameBody,
  CreateSubscription200,
  CreateSubscriptionBody,
  CreateSubscriptionV2200,
  CreateSubscriptionV2Body,
  ErrorMessage,
  GenerateStatement200,
  GetAccountParams,
  GetAccountsParams,
  GetPaymentLimits200,
  GetScheduledTransactions200,
  GetSingleTransactionV2200,
  GetTransactionsCsvParams,
  GetTransactionsParams,
  GetTransactionsV2200,
  GetTransactionsV2Params,
  ProcessedSubscriptionEntity,
  ReturnTypeTypeofgenerateStatementSchemaParse,
  ReturnTypeTypeofsubscriptionDDBB2CResponseSchemaParse,
  StatementList,
  TotalBalanceResponse,
  Transaction,
  TransactionResponse,
  UpdateNicknameV2Body,
  Uuid,
} from '../api.schemas';
import { customAxios } from '../../axios';

/**
 * 
Get all accounts associated with a business
 * @summary Get All Accounts
 */
export const getAccountsV2 = (signal?: AbortSignal) => {
  return customAxios<AllAccountsV2>({ url: `/v2/accounts`, method: 'GET', signal });
};

export const getGetAccountsV2QueryKey = () => {
  return [`/v2/accounts`] as const;
};

export const getGetAccountsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountsV2>>,
  TError = BasicErrorResponseInternalServerError,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountsV2QueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountsV2>>> = ({ signal }) => getAccountsV2(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountsV2>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAccountsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getAccountsV2>>>;
export type GetAccountsV2QueryError = BasicErrorResponseInternalServerError;

export function useGetAccountsV2<
  TData = Awaited<ReturnType<typeof getAccountsV2>>,
  TError = BasicErrorResponseInternalServerError,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccountsV2<
  TData = Awaited<ReturnType<typeof getAccountsV2>>,
  TError = BasicErrorResponseInternalServerError,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccountsV2<
  TData = Awaited<ReturnType<typeof getAccountsV2>>,
  TError = BasicErrorResponseInternalServerError,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get All Accounts
 */

export function useGetAccountsV2<
  TData = Awaited<ReturnType<typeof getAccountsV2>>,
  TError = BasicErrorResponseInternalServerError,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountsV2>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAccountsV2QueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create Account
 * @summary Create Account
 */
export const createSubscriptionV2 = (createSubscriptionV2Body: CreateSubscriptionV2Body, signal?: AbortSignal) => {
  return customAxios<CreateSubscriptionV2200>({
    url: `/v2/accounts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSubscriptionV2Body,
    signal,
  });
};

export const getCreateSubscriptionV2MutationOptions = <
  TError =
    | BasicErrorResponseProductnotfound
    | BasicErrorResponseValidationerroronbody
    | BasicErrorResponseInternalServerError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSubscriptionV2>>,
    TError,
    { data: CreateSubscriptionV2Body },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSubscriptionV2>>,
  TError,
  { data: CreateSubscriptionV2Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSubscriptionV2>>,
    { data: CreateSubscriptionV2Body }
  > = (props) => {
    const { data } = props ?? {};

    return createSubscriptionV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSubscriptionV2MutationResult = NonNullable<Awaited<ReturnType<typeof createSubscriptionV2>>>;
export type CreateSubscriptionV2MutationBody = CreateSubscriptionV2Body;
export type CreateSubscriptionV2MutationError =
  | BasicErrorResponseProductnotfound
  | BasicErrorResponseValidationerroronbody
  | BasicErrorResponseInternalServerError;

/**
 * @summary Create Account
 */
export const useCreateSubscriptionV2 = <
  TError =
    | BasicErrorResponseProductnotfound
    | BasicErrorResponseValidationerroronbody
    | BasicErrorResponseInternalServerError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSubscriptionV2>>,
    TError,
    { data: CreateSubscriptionV2Body },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createSubscriptionV2>>,
  TError,
  { data: CreateSubscriptionV2Body },
  TContext
> => {
  const mutationOptions = getCreateSubscriptionV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get account by id
 * @summary Get Account by ID
 */
export const getAccountV2 = (id: string, signal?: AbortSignal) => {
  return customAxios<AccountV2>({ url: `/v2/accounts/${id}`, method: 'GET', signal });
};

export const getGetAccountV2QueryKey = (id: string) => {
  return [`/v2/accounts/${id}`] as const;
};

export const getGetAccountV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountV2>>,
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountV2QueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountV2>>> = ({ signal }) => getAccountV2(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountV2>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAccountV2QueryResult = NonNullable<Awaited<ReturnType<typeof getAccountV2>>>;
export type GetAccountV2QueryError =
  | BasicErrorResponseInvalidAccount
  | BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid
  | BasicErrorResponseValidationerrorInvaliduuid
  | BasicErrorResponseInternalServerError;

export function useGetAccountV2<
  TData = Awaited<ReturnType<typeof getAccountV2>>,
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccountV2<
  TData = Awaited<ReturnType<typeof getAccountV2>>,
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccountV2<
  TData = Awaited<ReturnType<typeof getAccountV2>>,
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Account by ID
 */

export function useGetAccountV2<
  TData = Awaited<ReturnType<typeof getAccountV2>>,
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseTheserverhasnotfoundanythingmatchingtheaccountid
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountV2>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAccountV2QueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update the nickname of a bank account
 * @summary Update Account Nickname
 */
export const updateNicknameV2 = (id: string, updateNicknameV2Body: UpdateNicknameV2Body) => {
  return customAxios<void>({
    url: `/v2/accounts/${id}/name`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateNicknameV2Body,
  });
};

export const getUpdateNicknameV2MutationOptions = <
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseBusinessalreadyhasanaccountwithsamenickname
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNicknameV2>>,
    TError,
    { id: string; data: UpdateNicknameV2Body },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNicknameV2>>,
  TError,
  { id: string; data: UpdateNicknameV2Body },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNicknameV2>>,
    { id: string; data: UpdateNicknameV2Body }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateNicknameV2(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNicknameV2MutationResult = NonNullable<Awaited<ReturnType<typeof updateNicknameV2>>>;
export type UpdateNicknameV2MutationBody = UpdateNicknameV2Body;
export type UpdateNicknameV2MutationError =
  | BasicErrorResponseInvalidAccount
  | BasicErrorResponseBusinessalreadyhasanaccountwithsamenickname
  | BasicErrorResponseValidationerrorInvaliduuid
  | BasicErrorResponseInternalServerError;

/**
 * @summary Update Account Nickname
 */
export const useUpdateNicknameV2 = <
  TError =
    | BasicErrorResponseInvalidAccount
    | BasicErrorResponseBusinessalreadyhasanaccountwithsamenickname
    | BasicErrorResponseValidationerrorInvaliduuid
    | BasicErrorResponseInternalServerError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNicknameV2>>,
    TError,
    { id: string; data: UpdateNicknameV2Body },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNicknameV2>>,
  TError,
  { id: string; data: UpdateNicknameV2Body },
  TContext
> => {
  const mutationOptions = getUpdateNicknameV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get detail for a specific transaction belonging to a specific account (v2)
 * @summary Get Transaction
 */
export const getSingleTransactionV2 = (id: string, transactionId: string, signal?: AbortSignal) => {
  return customAxios<GetSingleTransactionV2200>({
    url: `/v2/accounts/${id}/transactions/${transactionId}`,
    method: 'GET',
    signal,
  });
};

export const getGetSingleTransactionV2QueryKey = (id: string, transactionId: string) => {
  return [`/v2/accounts/${id}/transactions/${transactionId}`] as const;
};

export const getGetSingleTransactionV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getSingleTransactionV2>>,
  TError = unknown,
>(
  id: string,
  transactionId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSingleTransactionV2QueryKey(id, transactionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSingleTransactionV2>>> = ({ signal }) =>
    getSingleTransactionV2(id, transactionId, signal);

  return { queryKey, queryFn, enabled: !!(id && transactionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSingleTransactionV2>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSingleTransactionV2QueryResult = NonNullable<Awaited<ReturnType<typeof getSingleTransactionV2>>>;
export type GetSingleTransactionV2QueryError = unknown;

export function useGetSingleTransactionV2<TData = Awaited<ReturnType<typeof getSingleTransactionV2>>, TError = unknown>(
  id: string,
  transactionId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSingleTransactionV2<TData = Awaited<ReturnType<typeof getSingleTransactionV2>>, TError = unknown>(
  id: string,
  transactionId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>,
        'initialData'
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSingleTransactionV2<TData = Awaited<ReturnType<typeof getSingleTransactionV2>>, TError = unknown>(
  id: string,
  transactionId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Transaction
 */

export function useGetSingleTransactionV2<TData = Awaited<ReturnType<typeof getSingleTransactionV2>>, TError = unknown>(
  id: string,
  transactionId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransactionV2>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSingleTransactionV2QueryOptions(id, transactionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get transactions for a account (v2)
 * @summary Get Transactions
 */
export const getTransactionsV2 = (id: string, params?: GetTransactionsV2Params, signal?: AbortSignal) => {
  return customAxios<GetTransactionsV2200>({ url: `/v2/accounts/${id}/transactions`, method: 'GET', params, signal });
};

export const getGetTransactionsV2QueryKey = (id: string, params?: GetTransactionsV2Params) => {
  return [`/v2/accounts/${id}/transactions`, ...(params ? [params] : [])] as const;
};

export const getGetTransactionsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactionsV2>>,
  TError = ErrorMessage,
>(
  id: string,
  params?: GetTransactionsV2Params,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsV2QueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsV2>>> = ({ signal }) =>
    getTransactionsV2(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactionsV2>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTransactionsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsV2>>>;
export type GetTransactionsV2QueryError = ErrorMessage;

export function useGetTransactionsV2<TData = Awaited<ReturnType<typeof getTransactionsV2>>, TError = ErrorMessage>(
  id: string,
  params: undefined | GetTransactionsV2Params,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTransactionsV2<TData = Awaited<ReturnType<typeof getTransactionsV2>>, TError = ErrorMessage>(
  id: string,
  params?: GetTransactionsV2Params,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTransactionsV2<TData = Awaited<ReturnType<typeof getTransactionsV2>>, TError = ErrorMessage>(
  id: string,
  params?: GetTransactionsV2Params,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Transactions
 */

export function useGetTransactionsV2<TData = Awaited<ReturnType<typeof getTransactionsV2>>, TError = ErrorMessage>(
  id: string,
  params?: GetTransactionsV2Params,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsV2>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTransactionsV2QueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a list of statements associated with an account
 * @summary Retrieve statements for an account
 */
export const getStatements = (accountId: Uuid, signal?: AbortSignal) => {
  return customAxios<StatementList>({ url: `/accounts/${accountId}/statements`, method: 'GET', signal });
};

export const getGetStatementsQueryKey = (accountId: Uuid) => {
  return [`/accounts/${accountId}/statements`] as const;
};

export const getGetStatementsQueryOptions = <TData = Awaited<ReturnType<typeof getStatements>>, TError = unknown>(
  accountId: Uuid,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStatementsQueryKey(accountId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatements>>> = ({ signal }) =>
    getStatements(accountId, signal);

  return { queryKey, queryFn, enabled: !!accountId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStatements>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetStatementsQueryResult = NonNullable<Awaited<ReturnType<typeof getStatements>>>;
export type GetStatementsQueryError = unknown;

export function useGetStatements<TData = Awaited<ReturnType<typeof getStatements>>, TError = unknown>(
  accountId: Uuid,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetStatements<TData = Awaited<ReturnType<typeof getStatements>>, TError = unknown>(
  accountId: Uuid,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetStatements<TData = Awaited<ReturnType<typeof getStatements>>, TError = unknown>(
  accountId: Uuid,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Retrieve statements for an account
 */

export function useGetStatements<TData = Awaited<ReturnType<typeof getStatements>>, TError = unknown>(
  accountId: Uuid,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatements>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetStatementsQueryOptions(accountId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a statement for an account.
 * @summary Retrieve a statement
 */
export const getOfficialStatement = (accountId: Uuid, startDate: string, endDate: string, signal?: AbortSignal) => {
  return customAxios<string>({
    url: `/accounts/${accountId}/statements/official/${startDate}/${endDate}`,
    method: 'GET',
    signal,
  });
};

export const getGetOfficialStatementQueryKey = (accountId: Uuid, startDate: string, endDate: string) => {
  return [`/accounts/${accountId}/statements/official/${startDate}/${endDate}`] as const;
};

export const getGetOfficialStatementQueryOptions = <
  TData = Awaited<ReturnType<typeof getOfficialStatement>>,
  TError = unknown,
>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOfficialStatementQueryKey(accountId, startDate, endDate);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOfficialStatement>>> = ({ signal }) =>
    getOfficialStatement(accountId, startDate, endDate, signal);

  return { queryKey, queryFn, enabled: !!(accountId && startDate && endDate), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOfficialStatement>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetOfficialStatementQueryResult = NonNullable<Awaited<ReturnType<typeof getOfficialStatement>>>;
export type GetOfficialStatementQueryError = unknown;

export function useGetOfficialStatement<TData = Awaited<ReturnType<typeof getOfficialStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetOfficialStatement<TData = Awaited<ReturnType<typeof getOfficialStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetOfficialStatement<TData = Awaited<ReturnType<typeof getOfficialStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Retrieve a statement
 */

export function useGetOfficialStatement<TData = Awaited<ReturnType<typeof getOfficialStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfficialStatement>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetOfficialStatementQueryOptions(accountId, startDate, endDate, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getInterimStatement = (accountId: Uuid, startDate: string, endDate: string, signal?: AbortSignal) => {
  return customAxios<string>({
    url: `/accounts/${accountId}/statements/interim/${startDate}/${endDate}`,
    method: 'GET',
    signal,
  });
};

export const getGetInterimStatementQueryKey = (accountId: Uuid, startDate: string, endDate: string) => {
  return [`/accounts/${accountId}/statements/interim/${startDate}/${endDate}`] as const;
};

export const getGetInterimStatementQueryOptions = <
  TData = Awaited<ReturnType<typeof getInterimStatement>>,
  TError = unknown,
>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInterimStatementQueryKey(accountId, startDate, endDate);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInterimStatement>>> = ({ signal }) =>
    getInterimStatement(accountId, startDate, endDate, signal);

  return { queryKey, queryFn, enabled: !!(accountId && startDate && endDate), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInterimStatement>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetInterimStatementQueryResult = NonNullable<Awaited<ReturnType<typeof getInterimStatement>>>;
export type GetInterimStatementQueryError = unknown;

export function useGetInterimStatement<TData = Awaited<ReturnType<typeof getInterimStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInterimStatement<TData = Awaited<ReturnType<typeof getInterimStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetInterimStatement<TData = Awaited<ReturnType<typeof getInterimStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetInterimStatement<TData = Awaited<ReturnType<typeof getInterimStatement>>, TError = unknown>(
  accountId: Uuid,
  startDate: string,
  endDate: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getInterimStatement>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetInterimStatementQueryOptions(accountId, startDate, endDate, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const generateStatement = (
  returnTypeTypeofgenerateStatementSchemaParse: ReturnTypeTypeofgenerateStatementSchemaParse,
  signal?: AbortSignal
) => {
  return customAxios<GenerateStatement200>({
    url: `/accounts/statements/generate`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: returnTypeTypeofgenerateStatementSchemaParse,
    signal,
  });
};

export const getGenerateStatementMutationOptions = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateStatement>>,
    TError,
    { data: ReturnTypeTypeofgenerateStatementSchemaParse },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateStatement>>,
  TError,
  { data: ReturnTypeTypeofgenerateStatementSchemaParse },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateStatement>>,
    { data: ReturnTypeTypeofgenerateStatementSchemaParse }
  > = (props) => {
    const { data } = props ?? {};

    return generateStatement(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateStatementMutationResult = NonNullable<Awaited<ReturnType<typeof generateStatement>>>;
export type GenerateStatementMutationBody = ReturnTypeTypeofgenerateStatementSchemaParse;
export type GenerateStatementMutationError = ErrorMessage;

export const useGenerateStatement = <TError = ErrorMessage, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateStatement>>,
    TError,
    { data: ReturnTypeTypeofgenerateStatementSchemaParse },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateStatement>>,
  TError,
  { data: ReturnTypeTypeofgenerateStatementSchemaParse },
  TContext
> => {
  const mutationOptions = getGenerateStatementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 
Get all subscriptions associated with a entity account
 */
export const getAccounts = (params?: GetAccountsParams, signal?: AbortSignal) => {
  return customAxios<AllSubscriptionsResponse>({ url: `/accounts`, method: 'GET', params, signal });
};

export const getGetAccountsQueryKey = (params?: GetAccountsParams) => {
  return [`/accounts`, ...(params ? [params] : [])] as const;
};

export const getGetAccountsQueryOptions = <TData = Awaited<ReturnType<typeof getAccounts>>, TError = unknown>(
  params?: GetAccountsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccounts>>> = ({ signal }) => getAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccounts>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccounts>>>;
export type GetAccountsQueryError = unknown;

export function useGetAccounts<TData = Awaited<ReturnType<typeof getAccounts>>, TError = unknown>(
  params: undefined | GetAccountsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccounts<TData = Awaited<ReturnType<typeof getAccounts>>, TError = unknown>(
  params?: GetAccountsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccounts<TData = Awaited<ReturnType<typeof getAccounts>>, TError = unknown>(
  params?: GetAccountsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAccounts<TData = Awaited<ReturnType<typeof getAccounts>>, TError = unknown>(
  params?: GetAccountsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccounts>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAccountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const createSubscription = (createSubscriptionBody: CreateSubscriptionBody, signal?: AbortSignal) => {
  return customAxios<CreateSubscription200>({
    url: `/accounts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSubscriptionBody,
    signal,
  });
};

export const getCreateSubscriptionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSubscription>>,
    TError,
    { data: CreateSubscriptionBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSubscription>>,
  TError,
  { data: CreateSubscriptionBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSubscription>>,
    { data: CreateSubscriptionBody }
  > = (props) => {
    const { data } = props ?? {};

    return createSubscription(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof createSubscription>>>;
export type CreateSubscriptionMutationBody = CreateSubscriptionBody;
export type CreateSubscriptionMutationError = unknown;

export const useCreateSubscription = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSubscription>>,
    TError,
    { data: CreateSubscriptionBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createSubscription>>,
  TError,
  { data: CreateSubscriptionBody },
  TContext
> => {
  const mutationOptions = getCreateSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAccount = (id: string, params: GetAccountParams, signal?: AbortSignal) => {
  return customAxios<ProcessedSubscriptionEntity>({ url: `/accounts/${id}`, method: 'GET', params, signal });
};

export const getGetAccountQueryKey = (id: string, params: GetAccountParams) => {
  return [`/accounts/${id}`, ...(params ? [params] : [])] as const;
};

export const getGetAccountQueryOptions = <TData = Awaited<ReturnType<typeof getAccount>>, TError = unknown>(
  id: string,
  params: GetAccountParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccount>>> = ({ signal }) => getAccount(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccount>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getAccount>>>;
export type GetAccountQueryError = unknown;

export function useGetAccount<TData = Awaited<ReturnType<typeof getAccount>>, TError = unknown>(
  id: string,
  params: GetAccountParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccount<TData = Awaited<ReturnType<typeof getAccount>>, TError = unknown>(
  id: string,
  params: GetAccountParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccount<TData = Awaited<ReturnType<typeof getAccount>>, TError = unknown>(
  id: string,
  params: GetAccountParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAccount<TData = Awaited<ReturnType<typeof getAccount>>, TError = unknown>(
  id: string,
  params: GetAccountParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccount>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAccountQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the total balance of all subscriptions under an entity account
 */
export const getTotalBalance = (signal?: AbortSignal) => {
  return customAxios<TotalBalanceResponse>({ url: `/accounts/balances/total`, method: 'GET', signal });
};

export const getGetTotalBalanceQueryKey = () => {
  return [`/accounts/balances/total`] as const;
};

export const getGetTotalBalanceQueryOptions = <
  TData = Awaited<ReturnType<typeof getTotalBalance>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTotalBalanceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTotalBalance>>> = ({ signal }) => getTotalBalance(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTotalBalance>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTotalBalanceQueryResult = NonNullable<Awaited<ReturnType<typeof getTotalBalance>>>;
export type GetTotalBalanceQueryError = unknown;

export function useGetTotalBalance<TData = Awaited<ReturnType<typeof getTotalBalance>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>> &
    Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>, 'initialData'>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTotalBalance<TData = Awaited<ReturnType<typeof getTotalBalance>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>> &
    Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>, 'initialData'>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTotalBalance<TData = Awaited<ReturnType<typeof getTotalBalance>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetTotalBalance<TData = Awaited<ReturnType<typeof getTotalBalance>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTotalBalance>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTotalBalanceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Change the nickname of a bank account
 */
export const changeNickname = (id: string, changeNicknameBody: ChangeNicknameBody, signal?: AbortSignal) => {
  return customAxios<ReturnTypeTypeofsubscriptionDDBB2CResponseSchemaParse>({
    url: `/accounts/${id}/name`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: changeNicknameBody,
    signal,
  });
};

export const getChangeNicknameMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeNickname>>,
    TError,
    { id: string; data: ChangeNicknameBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof changeNickname>>,
  TError,
  { id: string; data: ChangeNicknameBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof changeNickname>>,
    { id: string; data: ChangeNicknameBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return changeNickname(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangeNicknameMutationResult = NonNullable<Awaited<ReturnType<typeof changeNickname>>>;
export type ChangeNicknameMutationBody = ChangeNicknameBody;
export type ChangeNicknameMutationError = unknown;

export const useChangeNickname = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof changeNickname>>,
    TError,
    { id: string; data: ChangeNicknameBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof changeNickname>>,
  TError,
  { id: string; data: ChangeNicknameBody },
  TContext
> => {
  const mutationOptions = getChangeNicknameMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 
Get transactions for a subscription
 */
export const getTransactions = (id: string, params: GetTransactionsParams, signal?: AbortSignal) => {
  return customAxios<TransactionResponse>({ url: `/accounts/${id}/transactions`, method: 'GET', params, signal });
};

export const getGetTransactionsQueryKey = (id: string, params: GetTransactionsParams) => {
  return [`/accounts/${id}/transactions`, ...(params ? [params] : [])] as const;
};

export const getGetTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = ErrorMessage,
>(
  id: string,
  params: GetTransactionsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactions>>> = ({ signal }) =>
    getTransactions(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactions>>>;
export type GetTransactionsQueryError = ErrorMessage;

export function useGetTransactions<TData = Awaited<ReturnType<typeof getTransactions>>, TError = ErrorMessage>(
  id: string,
  params: GetTransactionsParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTransactions<TData = Awaited<ReturnType<typeof getTransactions>>, TError = ErrorMessage>(
  id: string,
  params: GetTransactionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTransactions<TData = Awaited<ReturnType<typeof getTransactions>>, TError = ErrorMessage>(
  id: string,
  params: GetTransactionsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetTransactions<TData = Awaited<ReturnType<typeof getTransactions>>, TError = ErrorMessage>(
  id: string,
  params: GetTransactionsParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTransactionsQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 
Download transactions as CSV
 */
export const getTransactionsCsv = (id: string, params: GetTransactionsCsvParams, signal?: AbortSignal) => {
  return customAxios<string>({ url: `/accounts/${id}/transactions/download`, method: 'GET', params, signal });
};

export const getGetTransactionsCsvQueryKey = (id: string, params: GetTransactionsCsvParams) => {
  return [`/accounts/${id}/transactions/download`, ...(params ? [params] : [])] as const;
};

export const getGetTransactionsCsvQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactionsCsv>>,
  TError = unknown,
>(
  id: string,
  params: GetTransactionsCsvParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsCsvQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsCsv>>> = ({ signal }) =>
    getTransactionsCsv(id, params, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactionsCsv>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetTransactionsCsvQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsCsv>>>;
export type GetTransactionsCsvQueryError = unknown;

export function useGetTransactionsCsv<TData = Awaited<ReturnType<typeof getTransactionsCsv>>, TError = unknown>(
  id: string,
  params: GetTransactionsCsvParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTransactionsCsv<TData = Awaited<ReturnType<typeof getTransactionsCsv>>, TError = unknown>(
  id: string,
  params: GetTransactionsCsvParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetTransactionsCsv<TData = Awaited<ReturnType<typeof getTransactionsCsv>>, TError = unknown>(
  id: string,
  params: GetTransactionsCsvParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetTransactionsCsv<TData = Awaited<ReturnType<typeof getTransactionsCsv>>, TError = unknown>(
  id: string,
  params: GetTransactionsCsvParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsCsv>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetTransactionsCsvQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * 
Get detail for a specific transaction belonging to a specific subscription
 */
export const getSingleTransaction = (id: string, transactionId: string, signal?: AbortSignal) => {
  return customAxios<Transaction>({ url: `/accounts/${id}/transactions/${transactionId}`, method: 'GET', signal });
};

export const getGetSingleTransactionQueryKey = (id: string, transactionId: string) => {
  return [`/accounts/${id}/transactions/${transactionId}`] as const;
};

export const getGetSingleTransactionQueryOptions = <
  TData = Awaited<ReturnType<typeof getSingleTransaction>>,
  TError = unknown,
>(
  id: string,
  transactionId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSingleTransactionQueryKey(id, transactionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSingleTransaction>>> = ({ signal }) =>
    getSingleTransaction(id, transactionId, signal);

  return { queryKey, queryFn, enabled: !!(id && transactionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSingleTransaction>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSingleTransactionQueryResult = NonNullable<Awaited<ReturnType<typeof getSingleTransaction>>>;
export type GetSingleTransactionQueryError = unknown;

export function useGetSingleTransaction<TData = Awaited<ReturnType<typeof getSingleTransaction>>, TError = unknown>(
  id: string,
  transactionId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSingleTransaction<TData = Awaited<ReturnType<typeof getSingleTransaction>>, TError = unknown>(
  id: string,
  transactionId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSingleTransaction<TData = Awaited<ReturnType<typeof getSingleTransaction>>, TError = unknown>(
  id: string,
  transactionId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetSingleTransaction<TData = Awaited<ReturnType<typeof getSingleTransaction>>, TError = unknown>(
  id: string,
  transactionId: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSingleTransaction>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSingleTransactionQueryOptions(id, transactionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the payment limits associated with a bank account's product
 */
export const getPaymentLimits = (id: string, signal?: AbortSignal) => {
  return customAxios<GetPaymentLimits200>({ url: `/accounts/${id}/payment-limits`, method: 'GET', signal });
};

export const getGetPaymentLimitsQueryKey = (id: string) => {
  return [`/accounts/${id}/payment-limits`] as const;
};

export const getGetPaymentLimitsQueryOptions = <TData = Awaited<ReturnType<typeof getPaymentLimits>>, TError = unknown>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentLimitsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentLimits>>> = ({ signal }) =>
    getPaymentLimits(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentLimits>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPaymentLimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentLimits>>>;
export type GetPaymentLimitsQueryError = unknown;

export function useGetPaymentLimits<TData = Awaited<ReturnType<typeof getPaymentLimits>>, TError = unknown>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>> &
      Pick<DefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>, 'initialData'>;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentLimits<TData = Awaited<ReturnType<typeof getPaymentLimits>>, TError = unknown>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>> &
      Pick<UndefinedInitialDataOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>, 'initialData'>;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPaymentLimits<TData = Awaited<ReturnType<typeof getPaymentLimits>>, TError = unknown>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPaymentLimits<TData = Awaited<ReturnType<typeof getPaymentLimits>>, TError = unknown>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPaymentLimits>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPaymentLimitsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getScheduledTransactions = (id: string, signal?: AbortSignal) => {
  return customAxios<GetScheduledTransactions200>({ url: `/accounts/${id}/scheduled-payments`, method: 'GET', signal });
};

export const getGetScheduledTransactionsQueryKey = (id: string) => {
  return [`/accounts/${id}/scheduled-payments`] as const;
};

export const getGetScheduledTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getScheduledTransactions>>,
  TError = unknown,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetScheduledTransactionsQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getScheduledTransactions>>> = ({ signal }) =>
    getScheduledTransactions(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getScheduledTransactions>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetScheduledTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getScheduledTransactions>>>;
export type GetScheduledTransactionsQueryError = unknown;

export function useGetScheduledTransactions<
  TData = Awaited<ReturnType<typeof getScheduledTransactions>>,
  TError = unknown,
>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>,
        'initialData'
      >;
  }
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetScheduledTransactions<
  TData = Awaited<ReturnType<typeof getScheduledTransactions>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>,
        'initialData'
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetScheduledTransactions<
  TData = Awaited<ReturnType<typeof getScheduledTransactions>>,
  TError = unknown,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetScheduledTransactions<
  TData = Awaited<ReturnType<typeof getScheduledTransactions>>,
  TError = unknown,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScheduledTransactions>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetScheduledTransactionsQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
